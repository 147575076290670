﻿@font-face {
	src: local("Century Schoolbook Bold"), local("CenturySchoolbook-Bold"),
		url("/fonts/century_schoolbook-bold.eot") format("embedded-opentype"),
		url("/fonts/century_schoolbook-bold.ttf") format("truetype"),
		url("/fonts/century_schoolbook-bold.woff") format("woff"),
		url("/fonts/century_schoolbook-bold.woff2") format("woff2");
	font-family: "Century Schoolbook";
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	src: local("Open Sans Light"), local("OpenSans-Light"),
		url("/fonts/open_sans-light.eot") format("embedded-opentype"),
		url("/fonts/open_sans-light.ttf") format("truetype"),
		url("/fonts/open_sans-light.woff") format("woff"),
		url("/fonts/open_sans-light.woff2") format("woff2");
	unicode-range: U+0000-024F, U+0131, U+0152-0153, U+0259, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+1E00-1EFF, U+2000-206F, U+2074, U+20A0-20CF, U+2113, U+2122, U+2191, U+2193, U+2212, U+2215, U+2C60-2C7F, U+A720-A7FF, U+FEFF, U+FFFD;
	font-family: "Open Sans";
	font-weight: 300;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"),
		url("/fonts/open_sans-light_italic.eot") format("embedded-opentype"),
		url("/fonts/open_sans-light_italic.ttf") format("truetype"),
		url("/fonts/open_sans-light_italic.woff") format("woff"),
		url("/fonts/open_sans-light_italic.woff2") format("woff2");
	unicode-range: U+0000-024F, U+0131, U+0152-0153, U+0259, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+1E00-1EFF, U+2000-206F, U+2074, U+20A0-20CF, U+2113, U+2122, U+2191, U+2193, U+2212, U+2215, U+2C60-2C7F, U+A720-A7FF, U+FEFF, U+FFFD;
	font-family: "Open Sans";
	font-weight: 300;
	font-style: italic;
	font-display: fallback;
}
@font-face {
	src: local("Open Sans Regular"), local("OpenSans-Regular"),
		url("/fonts/open_sans-regular.eot") format("embedded-opentype"),
		url("/fonts/open_sans-regular.ttf") format("truetype"),
		url("/fonts/open_sans-regular.woff") format("woff"),
		url("/fonts/open_sans-regular.woff2") format("woff2");
	unicode-range: U+0000-024F, U+0131, U+0152-0153, U+0259, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+1E00-1EFF, U+2000-206F, U+2074, U+20A0-20CF, U+2113, U+2122, U+2191, U+2193, U+2212, U+2215, U+2C60-2C7F, U+A720-A7FF, U+FEFF, U+FFFD;
	font-family: "Open Sans";
	font-weight: 400;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	src: local("Open Sans Semibold"), local("OpenSans-Semibold"),
		url("/fonts/open_sans-semibold.eot") format("embedded-opentype"),
		url("/fonts/open_sans-semibold.ttf") format("truetype"),
		url("/fonts/open_sans-semibold.woff") format("woff"),
		url("/fonts/open_sans-semibold.woff2") format("woff2");
	unicode-range: U+0000-024F, U+0131, U+0152-0153, U+0259, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+1E00-1EFF, U+2000-206F, U+2074, U+20A0-20CF, U+2113, U+2122, U+2191, U+2193, U+2212, U+2215, U+2C60-2C7F, U+A720-A7FF, U+FEFF, U+FFFD;
	font-family: "Open Sans";
	font-weight: 600;
	font-style: normal;
	font-display: fallback;
}
