﻿main {
	display: block;
}
template {
	display: none;
}
input,
textarea,
ul {
	padding: 0;
	padding: unset;
}
input,
textarea {
	border: none;
	border: unset;
}
blockquote,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
	margin: 0;
	margin: unset;
}
address,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
	font: inherit;
	font: unset;
}
:link,
:visited {
	text-decoration: inherit;
	text-decoration: unset;
}
:link,
:visited {
	color: currentColor;
	color: unset;
}
input,
textarea {
	background: unset;
}
