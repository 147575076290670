@import "reset";
@import "fonts";
$site-width: 960px;
$primary-color: #9A1220;
$secondary-color: #038A1E;
html {
	font: 1em/1.5 "Open Sans", sans-serif;
	color: scale-color($primary-color, $lightness: -60%);
	background-color: #FFFFFC;
	quotes: "\201C" "\201D";
	&.-locked {
		overflow: hidden;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
	font-family: "Century Schoolbook", serif;
}
.site {
	max-width: $site-width;
	margin: {
		right: auto;
		left: auto;
	}
	.site-header {
		margin: {
			top: 1.5rem;
			bottom: 1.5rem;
		}
		@media (min-width: $site-width) {
			margin: {
				top: 3rem;
				bottom: 3rem;
			}
		}
		@supports (display: grid) {
			@media (min-width: 768px) {
				display: grid;
				padding: {
					right: 1rem;
					left: 1rem;
				}
				grid-template-areas: "heading empty  empty  "
				                     "heading pages  pages  "
									 "heading social contact";
				grid-gap: 0.75rem 1rem;
			}
			@media (min-width: $site-width) {
				padding: {
					right: 2rem;
					left: 2rem;
				}
				grid-gap: 1.5rem 2rem;
			}
		}
		h1 {
			text-align: center;
			a {
				display: inline-block;
			}
			img {
				display: block;
				height: 6rem;
			}
		}
		hgroup {
			padding: {
				right: 1rem;
				left: 1rem;
			}
			margin-bottom: 0.75rem;
			@media (min-width: $site-width) {
				padding: {
					right: 2rem;
					left: 2rem;
				}
			}
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: heading;
					padding: {
						right: unset;
						left: unset;
					}
					margin-bottom: unset;
				}
			}
		}
		ul {
			list-style: none;
		}
		.site-header-contact,
		.site-header-pages,
		.site-header-social {
			padding: {
				right: 1rem;
				left: 1rem;
			}
			@media (min-width: $site-width) {
				padding: {
					right: 2rem;
					left: 2rem;
				}
			}
			@supports (display: grid) {
				@media (min-width: 768px) {
					padding: {
						right: unset;
						left: unset;
					}
				}
			}
			a {
				display: block;
			}
		}
		.site-header-contact {
			margin-bottom: 1.5rem;
			@media (orientation: landscape) {
				display: flex;
				justify-content: center;
			}
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: contact;
					margin-bottom: unset;
					display: grid;
					grid-auto-flow: column;
					grid-column-gap: 1rem;
					justify-content: flex-end;
					align-items: center;
				}
			}
			a {
				padding: 0.25rem 0.5rem;
				text-align: center;
				@supports (display: grid) {
					@media (min-width: 768px) {
						padding: unset;
					}
				}
				&:focus,
				&:hover {
					text-decoration: underline;
				}
				&.-email {
					padding: 0.5rem;
					font-weight: 600;
					color: white;
					background-color: $secondary-color;
					text-transform: uppercase;
					@supports (display: grid) {
						@media (min-width: 768px) {
							padding: 0.5rem 1rem;
						}
					}
					&:focus,
					&:hover {
						background-color: scale-color($secondary-color, $lightness: +20%);
						text-decoration: unset;
					}
				}
			}
		}
		.site-header-pages {
			margin-bottom: 0.75rem;
			@media (orientation: landscape) {
				display: flex;
				justify-content: center;
			}
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: pages;
					display: grid;
					grid-auto-flow: column;
					grid-column-gap: 1rem;
					margin-bottom: unset;
					justify-content: start;
					align-items: end;
					text-transform: uppercase;
					font-size: 0.8em;
				}
				@media (min-width: $site-width) {
					font-size: unset;
					font-weight: 600;
					justify-content: space-between;
				}
			}
			a {
				padding: 0.25rem 0.5rem;
				text-align: center;
				@media (min-width: 768px) {
					color: $primary-color;
				}
				@supports (display: grid) {
					@media (min-width: 768px) {
						padding: unset;
						text-align: unset;
						line-height: 1.1;
					}
				}
			}
			:link,
			:visited {
				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.site-header-social {
			display: flex;
			justify-content: center;
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: social;
					justify-content: unset;
				}
			}
			img {
				display: block;
				max-width: 100%;
			}
			:link,
			:visited {
				border: 1px solid #EEECEC;
				&:focus,
				&:hover {
					border-color: $primary-color;
				}
			}
			> * {
				flex: 0 0 3rem;
				margin-right: 1rem;
				@supports (display: grid) {
					@media (min-width: 768px) {
						flex-basis: 2.5rem;
					}
				}
				&:last-child {
					margin-right: unset;
				}
			}
		}
	}
	.site-main {
		margin: {
			top: 1.5rem;
			bottom: 1.5rem;
		}
		@media (min-width: 768px) {
			margin: {
				top: 3rem;
				bottom: 3rem;
			}
		}
	}
	.site-footer {
		margin: {
			top: 1.5rem;
			bottom: 1.5rem;
		}
		padding-top: 1.5rem;
		border-top: 4px solid $primary-color;
		@supports (display: grid) {
			@media (min-width: 768px) {
				display: grid;
				padding: {
					right: 1rem;
					left: 1rem;
				}
				grid-template-areas: "logo empty    empty      "
				                     "logo pages    pages      "
				                     "logo social   social     "
				                     "logo contact  hours      "
				                     "logo contact  emergencies"
				                     "logo colophon recaptcha  ";
				grid-gap: 0.75rem 1rem;
			}
			@media (min-width: $site-width) {
				padding: {
					right: 2rem;
					left: 2rem;
				}
				grid-gap: 1.5rem 2rem;
			}
		}
		.site-footer-logo,
		.site-footer-pages,
		.site-footer-social,
		.site-footer-contact,
		.site-footer-hours,
		.site-footer-emergencies,
		.site-footer-colophon,
		.site-footer-recaptcha {
			padding: {
				right: 1rem;
				left: 1rem;
			}
			@media (min-width: $site-width) {
				padding: {
					right: 2rem;
					left: 2rem;
				}
			}
			@supports (display: grid) {
				@media (min-width: 768px) {
					padding: unset;
				}
			}
		}
		.site-footer-logo {
			margin: {
				right: auto;
				bottom: 1.5rem;
				left: auto;
			}
			text-align: center;
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: logo;
					margin-bottom: unset;
				}
			}
			a {
				display: inline-block;
			}
			img {
				height: 6rem;
			}
		}
		.site-footer-pages,
		.site-footer-social {
			list-style: none;
		}
		.site-footer-pages {
			margin-bottom: 1.5rem;
			text-align: center;
			@media (orientation: landscape) {
				display: flex;
				justify-content: center;
			}
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: pages;
					display: grid;
					grid-auto-flow: column;
					grid-column-gap: 1rem;
					justify-content: start;
					margin-bottom: unset;
					color: $primary-color;
					font-size: 0.8em;
					text-transform: uppercase;
				}
				@media (min-width: $site-width) {
					font-size: unset;
					font-weight: 600;
				}
			}
			a {
				padding: 0.25rem 0.5rem;
				text-align: center;
				@supports (display: grid) {
					@media (min-width: 768px) {
						padding: unset;
						text-align: unset;
					}
				}
			}
			:link,
			:visited {
				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.site-footer-social {
			display: flex;
			justify-content: center;
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: social;
					justify-content: start;
				}
			}
			a {
				display: block;
			}
			img {
				display: block;
				max-width: 100%;
			}
			:link,
			:visited {
				border: 1px solid #EEECEC;
				&:focus,
				&:hover {
					border-color: $primary-color;
				}
			}
			> * {
				flex: 0 0 3rem;
				margin-right: 1rem;
				@media (min-width: 768px) {
					flex-basis: 2.5rem;
				}
				&:last-child {
					margin-right: unset;
				}
			}
		}
		.site-footer-contact,
		.site-footer-hours,
		.site-footer-emergencies,
		.site-footer-colophon {
			:link,
			:visited {
				text-decoration: underline;
				&:focus,
				&:hover {
					color: $primary-color;
				}
			}
		}
		.site-footer-contact {
			margin-top: 1.5rem;
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: contact;
					margin-top: unset;
				}
			}
		}
		.site-footer-hours,
		.site-footer-emergencies,
		.site-footer-colophon,
		.site-footer-recaptcha {
			margin-top: 0.75rem;
			@supports (display: grid) {
				@media (min-width: 768px) {
					margin-top: unset;
				}
			}
		}
		.site-footer-hours {
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: hours;
				}
			}
		}
		.site-footer-emergencies {
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: emergencies;
				}
			}
		}
		.site-footer-colophon {
			font-size: 0.75em;
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: colophon;
				}
			}
		}
		.site-footer-recaptcha {
			@supports (display: grid) {
				@media (min-width: 768px) {
					grid-area: recaptcha;
				}
			}
		}
	}
	.-nobr {
		white-space: nowrap;
	}
}
.contact {
	padding: {
		right: 1rem;
		left: 1rem;
	}
	margin: {
		top: 1.5rem;
		bottom: 1.5rem;
	}
	@media (min-width: 768px) {
		margin: {
			top: 3rem;
			bottom: 3rem;
		}
	}
	@media (min-width: $site-width) {
		padding: {
			right: 2rem;
			left: 2rem;
		}
	}
	@supports (display: grid) {
		@media (min-width: 768px) {
			display: grid;
			grid-template-areas: "heading heading"
								 "hint    hint   "
								 "name    message"
								 "email   message"
								 "phone   message"
								 "tray    tray   ";
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 0.75rem 1rem;
		}
		@media (min-width: $site-width) {
			grid-column-gap: 2rem;
		}
	}
	h2 {
		margin-bottom: 1.5rem;
		font-size: 2em;
		line-height: 1;
		letter-spacing: -0.025em;
		@media (min-width: 768px) {
			text-align: center;
		}
		@media (min-width: $site-width) {
			font-size: 2.5em;
		}
		@supports (display: grid) {
			@media (min-width: 768px) {
				grid-area: heading;
				margin-bottom: 0.75rem;
			}
		}
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea {
		box-sizing: border-box;
		width: 100%;
		border-bottom: 1px solid currentColor;
		border-radius: unset;
		padding: 4px 1ch;
		&:focus {
			outline: unset;
			border-color: $primary-color;
			& + label {
				color: $primary-color;
			}
		}
	}
	input[type="submit"] {
		padding: 0.25rem 0.5rem;
		border-radius: unset;
		color: white;
		background-color: $secondary-color;
		min-width: 8em;
		&:enabled {
			&:focus,
			&:hover {
				background-color: scale-color($secondary-color, $lightness: +20%);
			}
		}
	}
	label {
		margin-top: 0.25em;
		font-size: 0.75em;
		text-transform: uppercase;
		font-weight: 600;
	}
	p {
		margin-top: 0.75rem;
		display: flex;
		flex-direction: column;
		@supports (display: grid) {
			@media (min-width: 768px) {
				margin-top: unset;
			}
		}
	}
	textarea {
		resize: vertical;
		@supports (display: grid) {
			@media (min-width: 768px) {
				height: 100%;
				resize: none;
				border: 1px solid currentColor;
			}
		}
	}
	.contact-name {
		@supports (display: grid) {
			@media (min-width: 768px) {
				grid-area: name;
			}
		}
	}
	.contact-email {
		@supports (display: grid) {
			@media (min-width: 768px) {
				grid-area: email;
			}
		}
	}
	.contact-phone {
		@supports (display: grid) {
			@media (min-width: 768px) {
				grid-area: phone;
			}
		}
	}
	.contact-message {
		@supports (display: grid) {
			@media (min-width: 768px) {
				grid-area: message;
			}
		}
	}
	.-hint {
		@supports (display: grid) {
			@media (min-width: 768px) {
				text-align: center;
				grid-area: hint;
			}
		}
	}
	.-tray {
		margin-top: 1.5rem;
		@supports (display: grid) {
			@media (min-width: 768px) {
				grid-area: tray;
				flex-direction: row-reverse;
				justify-content: flex-start;
				align-items: center;
				margin-top: unset;
			}
		}
	}
	:disabled {
		opacity: 0.5;
	}
	#contact-status {
		display: block;
		margin-top: 0.75rem;
		@media (min-width: 768px) {
			margin: {
				top: unset;
				right: 1rem;
			}
		}
	}
}
.page {
	font-weight: 300;
	.page-header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: {
			top: 1.5rem;
			bottom: 1.5rem;
		}
		h2 {
			margin-bottom: 0.75rem;
			font-size: 2em;
			line-height: 1;
			white-space: pre-line;
			@media (min-width: 768px) {
				font-size: 4em;
			}
		}
		hgroup {
			padding: {
				right: 1rem;
				left: 1rem;
			}
			position: absolute;
			color: white;
			text-align: center;
			text-shadow: 0 0 2em rgba(0, 0, 0, 0.6);
			@media (min-width: 756px) {
				text-shadow: 0 0 3em rgba(0, 0, 0, 0.6);
			}
		}
		.page-header-image {
			display: block;
			width: 100%;
		}
	}
	.page-hero {
		padding: {
			right: 1rem;
			left: 1rem;
		}
		margin: {
			top: 1.5rem;
			bottom: 1.5rem;
		}
		@media (min-width: 768px) {
			width: 30em;
			font-size: 1.25em;
			margin: 3rem auto;
		}
		@media (min-width: $site-width) {
			margin: {
				top: 6rem;
				bottom: 6rem;
			}
		}
		h2 {
			margin-bottom: 0.75rem;
			font-size: 2em;
			line-height: 1;
			letter-spacing: -0.025em;
		}
		.-lead {
			font-weight: 600;
		}
		:link,
		:visited {
			text-decoration: underline;
			&:focus,
			&:hover {
				color: $primary-color;
			}
		}
	}
	.page-nav {
		margin: {
			top: 1.5rem;
			bottom: 1.5rem;
		}
		@media (min-width: 768px) {
			margin: {
				top: 3rem;
				bottom: 3rem;
			}
		}
		@media (min-width: $site-width) {
			margin: {
				top: 6rem;
				bottom: 6rem;
			}
		}
		a {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-weight: 600;
			text-transform: uppercase;
			font-size: 5vw;
			line-height: 1.25;
			color: white;
			background-color: #999;
			@media (orientation: landscape), (min-width: 768px) {
				font-size: 2.5vw;
			}
			@media (min-width: $site-width) {
				font-size: 1.25rem;
			}
			&:focus,
			&:hover {
				background-color: #FFF;
			}
		}
		img,
		picture {
			display: block;
			width: 100%;
			mix-blend-mode: multiply;
		}
		span {
			position: absolute;
			white-space: pre-line;
			text-align: center;
			text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			@supports (display: grid) {
				display: grid;
				padding: {
					right: 1rem;
					left: 1rem;
				}
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 1rem;
				@media (orientation: landscape), (min-width: 768px) {
					grid-template-columns: repeat(4, 1fr);
				}
				@media (min-width: $site-width) {
					padding: {
						right: unset;
						left: unset;
					}
				}
			}
			> * {
				flex: 1 1 50%;
				@media (orientation: landscape), (min-width: 768px) {
					flex-basis: 25%;
				}
			}
		}
	}
}
.hero {
	margin: {
		top: 1.5rem;
		bottom: 1.5rem;
	}
	@media (orientation: landscape), (min-width: 768px) {
		display: flex;
	}
	@media (min-width: 768px) {
		margin: 3rem auto;
	}
	@media (min-width: $site-width) {
		margin: {
			top: 6rem;
			bottom: 6rem;
		}
	}
	h2 {
		margin-bottom: 0.75rem;
		font-size: 2em;
		line-height: 1;
		letter-spacing: -0.025em;
	}
	.-lead {
		font-weight: 600;
	}
	:link,
	:visited {
		text-decoration: underline;
		&:focus,
		&:hover {
			color: $primary-color;
		}
	}
	.hero-intro {
		padding: {
			right: 1rem;
			left: 1rem;
		}
		margin-bottom: 1.5rem;
		@media (orientation: landscape), (min-width: 768px) {
			flex: 0 1 30em;
			padding-right: 0.5rem;
			margin-bottom: unset;
			font-size: 1.25em;
		}
		@media (min-width: $site-width) {
			padding: {
				left: 2rem;
				right: 1rem;
			}
		}
		:link,
		:visited {
			font-weight: normal;
		}
	}
	.hero-services {
		padding: {
			right: 1rem;
			left: 1rem;
		}
		white-space: nowrap;
		@media (orientation: landscape), (min-width: 768px) {
			flex: 1 1 auto;
			padding-left: 0.5rem;
			font-size: 1.15em;
		}
		@media (min-width: $site-width) {
			padding: {
				left: 1rem;
				right: 2rem;
			}
		}
		ul {
			list-style: none;
			font-weight: normal;
		}
		.-well {
			padding: 0.75rem 1rem;
			background-color: scale-color($primary-color, $saturation: -90%, $lightness: +90%);
			h3 {
				font-size: 1.25em;
				margin-bottom: 0.75rem;
			}
		}
		.-more {
			font-weight: 300;
		}
	}
}
.detail {
	margin: {
		top: 1.5rem;
		bottom: 1.5rem;
	}
	@media (orientation: landscape), (min-width: 768px) {
		display: flex;
	}
	@media (min-width: 768px) {
		margin: {
			top: 3rem;
			bottom: 3rem;
		}
	}
	@media (min-width: $site-width) {
		align-items: center;
		margin: {
			top: 6rem;
			bottom: 6rem;
		}
	}
	.detail-media {
		padding: {
			right: 1rem;
			left: 1rem;
		}
		margin-bottom: 1.5rem;
		@media (orientation: landscape), (min-width: 768px) {
			flex: 1 1 33%;
			padding-right: 0.5rem;
			margin-bottom: unset;
		}
		@media (min-width: $site-width) {
			padding: {
				left: 2rem;
				right: 1rem;
			}
		}
		img {
			display: block;
			width: 100%;
		}
	}
	.detail-content {
		padding: {
			right: 1rem;
			left: 1rem;
		}
		@media (orientation: landscape), (min-width: 768px) {
			flex: 2 2 66%;
			padding-left: 0.5rem;
		}
		@media (min-width: $site-width) {
			padding: {
				left: 1rem;
				right: 2rem;
			}
		}
		h3 {
			font-size: 2em;
			line-height: 1;
			letter-spacing: -0.025em;
		}
		h4 {
			font-weight: 600;
			font-size: 0.75em;
			font-family: "Open Sans";
			text-transform: uppercase;
		}
		hgroup {
			margin-bottom: 0.75rem;
		}
		:link,
		:visited {
			text-decoration: underline;
			&:focus,
			&:hover {
				color: $primary-color;
			}
		}
	}
	&.-reverse {
		@media (orientation: landscape), (min-width: 768px) {
			flex-direction: row-reverse;
		}
		.detail-media {
			@media (orientation: landscape), (min-width: 768px) {
				padding: {
					right: 1rem;
					left: 0.5rem;
				}
			}
			@media (min-width: $site-width) {
				padding: {
					left: 1rem;
					right: 2rem;
				}
			}
		}
		.detail-content {
			@media (orientation: landscape), (min-width: 768px) {
				padding: {
					right: 0.5rem;
					left: 1rem;
				}
			}
			@media (min-width: $site-width) {
				padding: {
					left: 2rem;
					right: 1rem;
				}
			}
		}
	}
}
.gallery {
	margin: 1.5rem 1rem;
	@media (min-width: 768px) {
		font-size: 1.25em;
		margin: {
			top: 3rem;
			bottom: 3rem;
		}
	}
	@media (min-width: $site-width) {
		margin: 3rem 2rem;
	}
	.gallery-header {
		margin-bottom: 0.75rem;
		@media (min-width: 768px) {
			margin-bottom: 1.5rem;
			text-align: center;
		}
		h3 {
			font-size: 2em;
			line-height: 1;
			letter-spacing: -0.025em;
		}
		hgroup {
			margin-bottom: 0.75rem;
		}
		.-more_link {
			font-size: 0.75em;
			font-weight: normal;
			.-icon {
				height: 1.5em;
				margin-right: 0.5em;
				vertical-align: text-bottom;
			}
			:link,
			:visited {
				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.gallery-main {
		margin: {
			top: 0.75rem;
			bottom: 0.75rem;
		}
		@media (min-width: 768px) {
			margin: {
				top: 1.5rem;
				bottom: 1.5rem;
			}
		}
	}
	.gallery-photos {
		display: flex;
		flex-wrap: wrap;
		@supports (display: grid) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 1rem;
			@media (orientation: landscape), (min-width: 768px) {
				grid-template-columns: repeat(4, 1fr);
			}
		}
		> * {
			flex: 0 0 50%;
			@media (min-width: 768px) {
				flex-basis: 25%;
			}
			img {
				display: block;
				max-width: 100%;
			}
		}
	}
}
.testimonials {
	padding: 1.5rem 1rem;
	color: white;
	background-color: $primary-color;
	@media (min-width: 768px) {
		font-size: 1.25em;
		padding: {
			top: 3rem;
			bottom: 3rem;
		}
	}
	@media (min-width: $site-width) {
		margin: {
			top: 6rem;
			bottom: 6rem;
		}
	}
	blockquote {
		margin-bottom: 0.75rem;
		p {
			margin-bottom: 0.75rem;
		}
	}
	.testimonials-main {
		@media (min-width: 768px) {
			max-width: 30em;
			margin: {
				right: auto;
				left: auto;
			}
		}
		:link,
		:visited {
			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.-source {
		margin-bottom: 1.5rem;
		font-style: italic;
		font-style: oblique;
	}
	.-more_link {
		font-size: 0.75em;
		.-icon {
			height: 1.5em;
			margin-right: 0.5em;
			vertical-align: text-bottom;
		}
	}
}
.lightbox {
	padding: 1em;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	@media (min-width: 768px) {
		padding: 2em;
	}
	.lightbox-image {
		max-width: 100%;
		max-height: 100%;
		cursor: default;
	}
}
